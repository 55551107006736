<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="view-wrapper">
    <div class="main-container center-content">
      <div class="services-title">
        <span>Unsere Dienstleistungen</span>
        <span class="about-title"
          >Serviceportfolio in Werkvertrag</span
        >
      </div>
      <div class="card-list-wrapper services-list">
        <div class="card" v-for="(item, i) in items" :key="i">
          <div class="img-wrapper">
            <img
              :src="require(`../assets/services/${item.src}.png`)"
              height="100"
              :img-source="item.source"
            />
          </div>
          <div class="item-label">{{ item.label }}</div>
        </div>
        <div class="progress-container" @click="moveToPosition($event)">
          <div
            class="progress-bar"
            :style="{ width: progressWidth + '%' }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    progressWidth: 0,
    items: [
      {
        src: "online-shop_fulfillment",
        label: "Online-Shop Fulfillment",
        source:
          "https://www.flaticon.com/free-icons/ecommerce - created by vectorsmarket15 - Flaticon",
      },
      {
        src: "Co-packing",
        label: "Co-Packing",
        source:
          "https://www.flaticon.com/free-icons/mass-production - created by Iconjam - Flaticon",
      },
      {
        src: "Lagerlogistik",
        label: "Lagerlogistik",
        source:
          "https://www.flaticon.com/free-icons/forklift - created by vectorsmarket15 - Flaticon",
      },
      {
        src: "Produktionsarbeiten",
        label: "Produktionsarbeiten",
        source:
          "https://www.flaticon.com/free-icons/production - created by Freepik - Flaticon",
      },
      {
        src: "Produktveredelung",
        label: "Produktveredelung",
        source:
          "https://www.flaticon.com/free-icons/new-product - created by Parzival 1997 - Flaticon",
      },
      {
        src: "aktionen",
        label: "Aktionen",
        source:
          "https://www.flaticon.com/free-icons/shipping-and-delivery - created by AB Design - Flaticon",
      },
      {
        src: "qualitatskontrolle",
        label: "Qualitätskontrolle",
        source:
          "https://www.flaticon.com/free-icons/inspection - created by Freepik - Flaticon",
      },
      {
        src: "chargenverfolgung",
        label: "Chargenverfolgung",
        source:
          "https://www.flaticon.com/free-icons/barcode - created by amonrat rungreangfangsai - Flaticon",
      },
      {
        src: "etikettierung",
        label: "Etikettierung",
        source:
          "https://www.flaticon.com/free-icons/labeling - created by Freepik - Flaticon",
      },
      {
        src: "kodierung",
        label: "Kodierung",
        source:
          "https://www.flaticon.com/free-icons/parcel - created by surang - Flaticon",
      },
      {
        src: "shrinking",
        label: "Shrinking",
        source:
          '<a href="https://www.flaticon.com/free-icons/soft-drink - created by itim2101 - Flaticon',
      },
      {
        src: "sleeving",
        label: "Sleeving",
        source:
          "https://www.flaticon.com/free-icons/parcel - created by surang - Flaticon",
      },
    ],
  }),
  mounted() {
    this.listenToScroll();
  },
  methods: {
    listenToScroll() {
      this.servicesListWrapper = document.querySelector(
        ".card-list-wrapper.services-list"
      );
      if (this.servicesListWrapper) {
        this.servicesListWrapper.addEventListener(
          "scroll",
          this.updateProgress
        );
      }
    },
    updateProgress() {
      if (!this.servicesListWrapper) return;

      const scrollLeft = this.servicesListWrapper.scrollLeft;
      const windowWidth =
        this.servicesListWrapper.scrollWidth -
        this.servicesListWrapper.clientWidth;
      this.progressWidth = (scrollLeft / windowWidth) * 100;
    },
    moveToPosition(event) {
      if (!this.servicesListWrapper) return;

      const containerWidth = this.servicesListWrapper.clientWidth;
      const containerScrollWidth = this.servicesListWrapper.scrollWidth;
      const clickPosition = event.clientX;
      const progressContainerWidth = this.$el.clientWidth;

      const newScrollPosition =
        (clickPosition / progressContainerWidth) *
        (containerScrollWidth - containerWidth);

      this.servicesListWrapper.scroll({
        left: newScrollPosition,
        behavior: "smooth",
      });
    },
  },
  beforeDestroy() {
    if (this.servicesListWrapper) {
      this.servicesListWrapper.removeEventListener(
        "scroll",
        this.updateProgress
      );
    }
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  background: rgb(55, 55, 55);
}

.services-title {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.services-title span:first-child {
  color: #e7b202;
}

.services-title span:last-child {
  font-size: 2.1rem;
  font-weight: 700;
}

.card-list-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  gap: 16px;
}

.card {
  background: rgba(116, 116, 116, 0.282);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 330px; /* Minimum width of each card, adjust as needed */
 /* max-width: 375px;  Maximum width of each card, adjust as needed */
  font-size: 1.35em;
}

.card:before {
  content: "";
  background: inherit;
  filter: blur(20px);
  /* Change the blur amount as needed */
  position: absolute;
  top: -20px;
  bottom: -20px;
  left: -20px;
  right: -20px;
  z-index: -1;
}

.img-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  background: rgba(116, 116, 116, 0.282);
  border-radius: 4px;
  width: 100%;
  padding: 8px;
}

.item-label {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100%;
}

.progress-container {
  display: none;
}

@media screen and (max-width: 965px) {
  .main-container {
    padding: 32px;
  }

  .card-list-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .card-list-wrapper .card {
    font-size: 1.5rem;
    min-width: 250px;
  }
}

@media screen and (max-width: 615px) {
  .view-wrapper {
    height: unset;
  }

  .services-title span:last-child {
    font-size: 1.5em;
  }

  .card-list-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 16px;
  }

  .card {
    min-width: 47%;
    display: inline-table;
    flex: auto;
  }

  .item-label {
    padding: 0 16px;
  }

  .progress-container {
    display: inline;
  }

  .progress-bar {
    height: 100%;
    background-color: #e7b202;
    transition: width 0.2s ease;
    border-radius: 4px;
  }

  .card-list-wrapper .services-list {
    overflow-x: auto;
    overflow-y: hidden;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Firefox */
    scrollbar-width: none;

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
  }
}

.progress-container {
  position: absolute;
  bottom: 32px;
  left: 32px;
  right: 32px;
  height: 4px;
  background-color: #f3f3f3;
  border-radius: 4px;
}
</style>
