<template>
  <div class="view-wrapper">
    <div class="message-area center-content">

      <div class="message-left">
        <span class="message">
          Lass uns <label class="highlight">gemeinsam</label> eruieren, wie wir dir dabei helfen können.
        </span>
        <v-btn rounded color="#E7B202" x-large to="/kontakt">
          Lets go
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>

      <span class="d-divider"></span>

      <div class="message-right">
        <span class="message">Bereit für die nächste berufliche Herausforderung in der Logistik? <br> Bewirb dich jetzt!</span>
        <div>
        <a class="highlight email" @click.prevent="showModal=true">
          bewerbungen@packercrew.com
        </a>
        <DynamicModal :isVisible="showModal" @update:isVisible="showModal = $event">
          <template v-slot:header>
            <h3>Bestätigen Sie Ihre Handlung</h3>
          </template>
          <template v-slot:modal-content-wrapper>
            <div class="modal-content-wrapper">
              <p>Sind Sie sicher, dass Sie diesen Schritt wirklich gehen möchten?</p>
              <hr>
              <p>
                <span>
                  Mit dem Fortfahren bestätige ich, dass ich die
                  <router-link id="Datenschutz" to="/einwilligungserklarung" class="highlight">
                    Einwilligungserklärung
                  </router-link>
                  und die
                  <router-link id="Datenschutz" to="/datenschutzerklarung" class="highlight">
                    Datenschutzerklärung
                  </router-link>
                  gelesen habe und ihnen zustimme, um die Kontaktaufnahme zu ermöglichen.
                </span>
              </p>
            </div>
          </template>
          <hr>
          <template v-slot:footer>
            <div class="modal-footer">
              <v-btn rounded color="#E7B202" @click.prevent="confirmAction">Zustimmen</v-btn>
              <v-btn rounded @click.prevent="showModal = false">Abbrechen</v-btn>
            </div>
          </template>
        </DynamicModal>
      </div>
      </div>

    </div>

    <hr class="hr"/>

    <div class="footer-container center-content">
      <div class="footer-partners-wrapper">
        <div>
          <div class="highlight partners-title">Partnerschaften</div>
          <div class="partners-subtitle">Gemeinsam voran - Gemeinsam stark!</div>
        </div>
        <div class="partners-logo-wrapper">
          <a target="_blank" href="https://www.allcox.de/">
            <img class="logo logo-partner" src="../assets/partners/allcox_R.png" alt="Allcox GmbH Logo" />
          </a>
          <a target="_blank" href="https://www.allcox.de/">
            <img class="logo logo-partner" src="../assets/partners/allcox_persona_rgb.png" alt="Allcox Persona Logo" />
          </a>
        </div>
      </div>
      <hr class="hr"/>
      <div class="footer-top">

          <div class="footer-address">
            <div><a target="_blank" href="https://www.google.com/maps/place/PackerCrew+GmbH,+Lilienthalstra%C3%9Fe+10+-+12,+68642+B%C3%BCrstadt,+Alemanha/@49.6542,8.457358,17z/data=!3m1!1e3!4m6!3m5!1s0x4797d5c1dac8fd5f:0x323559b805555e64!8m2!3d49.6541995!4d8.4573583!16s%2Fg%2F11sv_63vc8?hl=pt-PT&gl=PT">PackerCrew GmbH <v-icon class="highlight">mdi-directions-fork</v-icon></a> </div>
            <div>Lilienthalstraße 10 - 12</div>
            <div>68642 Bürstadt</div>
          </div>

          <hr>
          <div class="menu-list social-media">
            <a id="Linkedin" href="https://www.linkedin.com/company/packercrew/" target="_blank">
              <v-icon>mdi-linkedin</v-icon>
              <div class="social-label">Linkedin</div>
            </a>
            <a id="Instagram" href="https://instagram.com/packercrew" target="_blank">
              <v-icon>mdi-instagram</v-icon>
              <div class="social-label">Instagram</div>
            </a>
            <a id="Facebook" href="https://www.facebook.com/people/Packer-Crew/100088005664479/" target="_blank">
              <v-icon>mdi-facebook</v-icon>
              <div class="social-label">Facebook</div>
            </a>
          </div>

          <hr>
          <div class="menu-list social-media">
            <div class="footer-docs">
              <router-link to="/kontakt" id="Contacts">Kontakt</router-link>
              <span class="v-divider"></span>
              <a id="InfoEmail" target="_blank" href="mailto:info@packercrew.com">info@packercrew.com</a>
            </div>
            <div class="footer-docs">
              <router-link to="/impressum" id="Impressum">Impressum</router-link>
              <span class="v-divider"></span>
              <router-link id="Datenschutz" to="/datenschutzerklarung">Datenschutz</router-link>
            </div>
            <router-link to="/urheberrecht" class="highlight">
              Copyrights © 2024 PackerCrew GmbH
            </router-link>
          </div>

          <div class="footer-logo">
            <img class="logo" src="../assets/logo-color-text-transparent.png" alt="PackerCrew GmbH Logo" />
          </div>

      </div>

    </div>
  </div>
</template>

<script>
import DynamicModal  from "../components/DynamicModal.vue";

export default {
  components: {
    DynamicModal,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    scrollToHome() {
      this.scrollToTarget("h1-Home", "/#home");
    },
    scrollToUberUns() {
      this.scrollToTarget("h2-UberUns", "/#uber_uns");
    },
    scrollToServices() {
      this.scrollToTarget("h4-Services", "/#dienstleistungen");
    },
    scrollToTarget(id, route) {
      const target = document.getElementById(id);
      if (target) {
        window.scrollTo({ top: target.offsetTop, behavior: "smooth" });
      } else {
        this.$router.push(route);
      }
    },
    confirmAction() {
      window.open("mailto:bewerbungen@packercrew.com");
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
@import "../styles/home-content.scss";

/* Your additional styles here */

.modal-content .modal-content-wrapper {
  width: 100%;
  font-size: 16px;
  color: #EFEFEF;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modal-content .modal-footer {
  display: flex!important;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
  bottom: 0;
  padding: auto;
}

.modal-content .modal-footer .v-btn + .v-btn {
  margin-left: 8px;

}
</style>
