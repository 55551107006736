<template>
  <div class="view-wrapper">
    <video autoplay :controls="showControls" muted loop src="../assets/videos/production.mp4" vid-source="Video by Paul Cruz: https://www.pexels.com/video/aerial-shot-of-interior-of-the-warehouse-4477613/
                                                        "></video>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showControls: false,
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.showControls = window.innerWidth < 825;
    },
  },
}
</script>

<style scoped>
.view-wrapper {
  min-width: 100vw;
  margin-right: 0;
  margin-left: 0;
  padding: 0;
}

video {
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 10px rgba(197, 197, 197, 0.2);
}
</style>