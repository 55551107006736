<template>
  <div v-if="isVisible" class="custom-dialog" @click.self="closeModal">
    <div class="modal-content">
      <slot name="header">
        <!-- This slot allows for a custom header if needed -->
        <h3>Modal Header</h3>
      </slot>
      <slot name="modal-content-wrapper">
        <!-- Default slot for modal body content -->
        Your dynamic content goes here.
      </slot>
      <slot name="footer" class="footer">
        <!-- This slot can be used for modal footer actions -->
        <button @click="closeModal">Close</button>
      </slot>
      <button class="close-button" @click.prevent="closeModal"><v-icon>mdi-close</v-icon></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DynamicModal',
  props: {
    isVisible: Boolean,
  },
  methods: {
    closeModal() {
      this.$emit('update:isVisible', false);
    },
  },
};
</script>

<style scoped>
.custom-dialog{
  color: #EFEFEF;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  font-size: 15px;
}

.modal-content {
  font-weight: 500;
  background-color: #333;
  box-shadow: 0px 0px 10px  rgba(231, 178, 2, 0.2);
  padding: 32px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 90vh;
  gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-content i {
  color: #e7b202;
}

.modal-content a {
  color: #e7b202 !important;
}


.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-content {
  background-color: #2A2A2A; /* A deeper shade for sophistication */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Softer shadow for depth */
  padding: 24px; /* Adjusted for better spacing */
  border-radius: 8px; /* More pronounced rounded corners */
}

</style>