<template>
  <div id="g-recaptcha" class="g-recaptcha" :data-sitekey="sitekey" data-size="image">
  </div>
</template>
<script>
export default {
  data() {
    return {
      sitekey: '6LfTLxAlAAAAAJ66pPXFgfThcFonmFz7TwWOrIxp',
      widgetId: 0
    }
  },
  methods: {
    execute() {
      window.grecaptcha.execute(this.widgetId)
    },
    reset() {
      window.grecaptcha.reset(this.widgetId)
    },
    render() {
      if (window.grecaptcha) {
        this.widgetId = window.grecaptcha.render('g-recaptcha', {
          sitekey: this.sitekey,
          size: 'normal',
          // the callback executed when the user solves the recaptcha
          callback: (response) => {
            // emit an event called verify with the response as payload
            this.$emit('verify', response)
            // reset the recaptcha widget so you can execute it again
            this.reset()
          },
          // change the text to "I'm not a robot" instead of "invisible"
          badge: "inline",
          'data-callback': "callback"
        })
      }
    }
  },
  mounted() {
    // render the recaptcha widget when the component is mounted
    this.render()
  },
  computed: {
    computedDataSize() {
      return (this.dataSize || this.$recaptcha.size) || 'normal'
    }
  },
}
</script>