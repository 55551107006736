<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="view-wrapper">
    <div class="main-container center-content">
      <div class="services-title">
        <span>Dienstleistungen von unseren Partnern</span>
      </div>
      <div class="card-list-wrapper services-list">
        <div class="card" v-for="(item, i) in items" :key="i">
          <div class="img-wrapper">
            <img
              :src="require(`../assets/partners/${item.src}.jpg`)"
              :img-source="item.source"
            />
          </div>
          <div class="item-label">{{ item.label }}</div>
        </div>
        <!--div class="progress-container" @click="moveToPosition($event)">
          <div
            class="progress-bar"
            :style="{ width: progressWidth + '%' }"
          ></div>
        </div-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    progressWidth: 0,
    items: [
      {
        src: "thumbnail_allcox_persona_rgb",
        label: "Arbeitnehmerüberlassung",
        source:
          "https://www.flaticon.com/free-icons/ecommerce - created by vectorsmarket15 - Flaticon",
      }
    ],
  }),
  mounted() {
    this.listenToScroll();
  },
  methods: {
    listenToScroll() {
      this.servicesListWrapper = document.querySelector(
        ".card-list-wrapper.services-list"
      );
      if (this.servicesListWrapper) {
        this.servicesListWrapper.addEventListener(
          "scroll",
          this.updateProgress
        );
      }
    },
    updateProgress() {
      if (!this.servicesListWrapper) return;

      const scrollLeft = this.servicesListWrapper.scrollLeft;
      const windowWidth =
        this.servicesListWrapper.scrollWidth -
        this.servicesListWrapper.clientWidth;
      this.progressWidth = (scrollLeft / windowWidth) * 100;
    },
    moveToPosition(event) {
      if (!this.servicesListWrapper) return;

      const containerWidth = this.servicesListWrapper.clientWidth;
      const containerScrollWidth = this.servicesListWrapper.scrollWidth;
      const clickPosition = event.clientX;
      const progressContainerWidth = this.$el.clientWidth;

      const newScrollPosition =
        (clickPosition / progressContainerWidth) *
        (containerScrollWidth - containerWidth);

      this.servicesListWrapper.scroll({
        left: newScrollPosition,
        behavior: "smooth",
      });
    },
  },
  beforeDestroy() {
    if (this.servicesListWrapper) {
      this.servicesListWrapper.removeEventListener(
        "scroll",
        this.updateProgress
      );
    }
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  background: rgb(55, 55, 55);
  gap: 16px;
}

.services-title {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.services-title span:first-child {
  color: #e7b202;
}

.card-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
}

.card {
  background: rgba(116, 116, 116, 0.282);
  /* Change the background color and opacity as needed */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  /* Change the box shadow as needed */
  position: relative;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 350px;
  max-width: 375px;
  font-size: 1.35em;
}

.card:before {
  content: "";
  background: inherit;
  filter: blur(20px);
  /* Change the blur amount as needed */
  position: absolute;
  top: -20px;
  bottom: -20px;
  left: -20px;
  right: -20px;
  z-index: -1;
}

.img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(116, 116, 116, 0.282);
  border-radius: 4px;
  width: 100%;
  height: 100px;
  padding: 12px;
}

.img-wrapper img{
  max-width: 100%;
  height: 75%;
  border-radius: 8px;
}


.item-label {
  display: flex;
  justify-content: center;
  text-align: center;
}

.progress-container {
  display: none;
}

@media screen and (max-width: 965px) {
  .main-container {
    padding: 32px;
  }

  .card-list-wrapper .card {
    font-size: 1.5rem;
    min-width: 250px;
  }
}

@media screen and (max-width: 615px) {
  .view-wrapper {
    height: unset;
  }

  .card-list-wrapper {
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 16px;
  }

  .card {
    min-width: 47%;
    display: inline-table;
    flex: auto;
  }

  .item-label {
    padding: 0 16px;
  }

  .progress-container {
    display: inline;
  }

  .progress-bar {
    height: 100%;
    background-color: #e7b202;
    transition: width 0.2s ease;
    border-radius: 4px;
  }

  .card-list-wrapper .services-list {
    overflow-x: auto;
    overflow-y: hidden;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Firefox */
    scrollbar-width: none;

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
  }
}

@media screen and (max-width: 425px) {
  .img-wrapper {
    height: 90px;
  }
  .item-label {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 400px) {
  .img-wrapper {
    height: 75px;
  }
  .item-label {
    font-size: 0.65em;
  }
}

.progress-container {
  position: absolute;
  bottom: 32px;
  left: 32px;
  right: 32px;
  height: 4px;
  background-color: #f3f3f3;
  border-radius: 4px;
}
</style>
