<template>
  <div class="view-wrapper doc-content">
      <div class="center-content">
          <h2>Copyrights © 2024 PackerCrew GmbH. Alle Rechte vorbehalten. </h2>
      
          <br/>
          <h3>Haftungsausschluss:</h3>
          <p class="doc-info">Die Informationen auf dieser Website dienen nur allgemeinen Informationszwecken. PackerCrew GmbH übernimmt keine Verantwortung für Fehler oder Auslassungen in den Inhalten dieser Website. In keinem Fall haftet die PackerCrew GmbH für besondere, direkte, indirekte, Folge- oder Nebenschäden oder für Schäden jeglicher Art, sei es aufgrund eines Vertrags, Fahrlässigkeit oder anderer unerlaubter Handlung, die sich aus der Nutzung dieser Website oder den Inhalten dieser Website ergeben.</p>

        <br/>
        <h3>Eigentum an geistigem Eigentum:</h3>
        <p class="doc-info">Soweit nicht anders angegeben, besitzt PackerCrew GmbH alle geistigen Eigentumsrechte, einschließlich Copyrights, an dieser Website und ihren Inhalten. Das auf dieser Website verwendete Pexels-Video ist unter der Pexels-Lizenzvereinbarung lizenziert, und die auf dieser Website verwendeten Flaticon-Symbole sind unter der Flaticon Basic License lizenziert. Das Eigentum und die geistigen Eigentumsrechte des Pexels-Videos und der Flaticon-Symbole verbleiben bei ihren ursprünglichen Erstellern.</p>

        <br/>
        <h3>Copyrights Verletzung: </h3>
        <p class="doc-info">PackerCrew GmbH respektiert die geistigen Eigentumsrechte Dritter und erwartet von seinen Nutzern dasselbe. Wenn Sie der Ansicht sind, dass Ihre Arbeit auf eine Weise kopiert wurde, die eine Copyrights Verletzung darstellt, wenden Sie sich bitte unter <a href="mailto:info@packercrew.com">info@packercrew.com</a> an uns und geben Sie uns folgende Informationen:</p>

        <ul>
            <li><a href="https://www.flaticon.com/free-icons/conveyor" aria-label="Conveyor icons on Flaticon">https://www.flaticon.com/free-icons/conveyor - erstellt von Smashicons - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/ecommerce" aria-label="Ecommerce icons on Flaticon">https://www.flaticon.com/free-icons/ecommerce - erstellt von vectorsmarket15 - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/mass-production" aria-label="Mass production icons on Flaticon">https://www.flaticon.com/free-icons/mass-production - erstellt von Iconjam - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/new-product" aria-label="New product icons on Flaticon">https://www.flaticon.com/free-icons/new-product - erstellt von Parzival 1997 - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/shipping-and-delivery" aria-label="Shipping and delivery icons on Flaticon">https://www.flaticon.com/free-icons/shipping-and-delivery - erstellt von AB Design - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/inspection" aria-label="Inspection icons on Flaticon">https://www.flaticon.com/free-icons/inspection - erstellt von Freepik - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/barcode" aria-label="Barcode icons on Flaticon">https://www.flaticon.com/free-icons/barcode - erstellt von amonrat rungreangfangsai - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/labeling" aria-label="Labeling icons on Flaticon">https://www.flaticon.com/free-icons/labeling - erstellt von Freepik - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/parcel" aria-label="Parcel icons on Flaticon">https://www.flaticon.com/free-icons/parcel - erstellt von surang - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/soft-drink" aria-label="Soft drink icons on Flaticon">www.flaticon.com/free-icons/soft-drink - erstellt von itim2101 - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/boxes" aria-label="Boxes icons on Flaticon">www.flaticon.com/free-icons/boxes - erstellt von Freepik - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/inventory" aria-label="Inventory icons on Flaticon">www.flaticon.com/free-icons/inventory - erstellt von Freepik - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/stock" aria-label="Stock icons on Flaticon">www.flaticon.com/free-icons/stock - erstellt von Freepik - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/order" aria-label="Order icons on Flaticon">www.flaticon.com/free-icons/order - erstellt von Freepik - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/product" aria-label="Product icons on Flaticon">www.flaticon.com/free-icons/product - erstellt von Creative Stall Premium - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/label" aria-label="Label icons on Flaticon">www.flaticon.com/free-icons/label - erstellt von FBJan - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/load-truck" aria-label="Load truck icons on Flaticon">www.flaticon.com/free-icons/load-truck - erstellt von Good Ware - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/return" aria-label="Return icons on Flaticon">www.flaticon.com/free-icons/return - erstellt von Freepik - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/forklift" aria-label="Forklift icons on Flaticon">www.flaticon.com/free-icons/forklift - erstellt von vectorsmarket15 - Flaticon </a></li>
            <li><a href="https://www.flaticon.com/free-icons/production" aria-label="Production icons on Flaticon">www.flaticon.com/free-icons/production - erstellt von Freepik - Flaticon </a></li>
          <br>
          <li><a href="https://www.pexels.com/video/aerial-shot-of-interior-of-the-warehouse-4477613/" aria-label="Screening icons on Flaticon">www.pexels.com/video/aerial-shot-of-interior-of-the-warehouse-4477613 - erstellt von Paul Cruz</a></li>

          <br>
          <h4>Logo von unseren Partnern</h4>
          <li><a href="https://www.allcox.de" aria-label="Allcox GmbH - Logo">Allcox GmbH</a></li>
          <li><a href="https://www.allcox.de" aria-label="Allcox Persona - Logo">Allcox Persona</a></li>

        </ul>

        <br/>
        <h3>Datum: </h3>
        <p class="doc-info">Dieser Copyrights Hinweis wurde zuletzt am 28. Februar 2024 aktualisiert.  </p>
        

        <p class="doc-info">
          Name: PackerCrew GmbH <br/>
          Adresse: Lilienthalstraße 10 - 12 | 68642 Bürstadt <br/>
          Telefonnummer: +49 176 43621439 | +49 174 2437092 <br/>
          E-Mail-Adresse: <a href="mailto:info@packercrew.com">info@packercrew.com</a>
        </p>

        <br/>
        <p class="doc-info">
          Hiermit erklären wir unter Strafe des Meineids, dass wir fest davon überzeugt sind, dass die Verwendung des Copyrights geschützten Materials auf dieser Website nicht vom Copyrights Inhaber, seinem Vertreter oder dem Gesetz autorisiert ist und dass die in diesem Hinweis bereitgestellten Informationen nach bestem Wissen und Gewissen korrekt sind. 
        </p>

        <br/>
        <p class="doc-info">
          Hiermit erklären wir unter Strafandrohung wegen Meineids, dass die oben genannten Informationen in diesem Hinweis korrekt sind und dass wir entweder der Copyrights Inhaber sind oder berechtigt sind, im Namen des Copyrights Inhaber zu handeln. 
        </p>
      </div>
  </div>
</template>


<script>
  export default {
    mounted() {
      window.scrollTo(0, 0);
    },
  };
</script>


<style scoped>

.doc-content {
  text-align: left;
}

a {
  text-decoration: none;
  color: #e7b202!important;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
