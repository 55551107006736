<template>
  <div class="view-wrapper">
    <div
      :class="!submit ? 'hidden' : 'alert-container'"
      :style="submitSuccess ? '' : 'background: red;'"
    >
      <span
        ><v-icon
          >mdi-{{
            submitSuccess ? "check-circle" : "explamation-circle"
          }}</v-icon
        ></span
      >
      <span>{{ notifyMessage }}</span>
    </div>
    <div class="contact-wrapper">
      <form @submit.prevent="handleSubmit">
        <span class="highlight-text">Lass uns sprechen!</span>
        <p class="form-title">Schreib uns an</p>
        <div class="form-content-wrapper">
          <div>
            <input
              placeholder="Name"
              type="text"
              id="name"
              v-model="name"
              required
            />
          </div>
          <div>
            <input
              placeholder="Telefonnummer"
              type="number"
              id="phone"
              v-model="phone"
              required
            />
          </div>
          <div>
            <input
              placeholder="Email"
              type="email"
              id="email"
              v-model="email"
              required
            />
          </div>
          <div>
            <input
              placeholder="Betreff"
              type="text"
              id="subject"
              v-model="subject"
              required
            />
          </div>
          <div class="form-message">
            <textarea
              placeholder="deine Nachricht"
              id="message"
              v-model="message"
              required
            ></textarea>
          </div>

          <label class="checkbox-wrapper">
            <input type="checkbox" v-model="isAgreed" />
            <span>
              Hiermit akzeptiere ich die
              <router-link
                id="Datenschutz"
                to="/einwilligungserklarung"
                class="highlight"
              >
                Einwilligungserklärung
              </router-link>
              und

              <router-link
                id="Datenschutz"
                to="/datenschutzerklarung"
                class="highlight"
              >
                Datenschutz
              </router-link>
              zur Kontaktaufnahme
            </span>
          </label>
          <v-btn
            rounded
            color="#E7B202"
            x-large
            type="submit"
            :class="!response || !isFormValid ? 'hidden' : ''"
            >Senden<v-icon>mdi-arrow-right</v-icon></v-btn
          >
          <vue-recaptcha
            ref="recaptcha"
            @verify="onVerify"
            :class="response || !isFormValid ? 'hidden' : ''"
          />
        </div>
      </form>
    </div>

    <div class="map-wrapper">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3955.2336355923794!2d8.457572899999999!3d49.654116099999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4797d5c1dac8fd5f%3A0x323559b805555e64!2sPackerCrew%20GmbH!5e1!3m2!1spt-PT!2spt!4v1688388215907!5m2!1spt-PT!2spt"
        width="100%"
        height="100%"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import RecaptchaComponent from "../components/RecaptchaComponent.vue";

const EMAIL_SERVICE_ID = "service_dpitbat";
const EMAIL_TEMPLATE_ID = "template_hyd1bi3";
const EMAIL_USER_ID = "WW9knHTSmEBQQRrUZ";

const SUCCESS_MESSAGE = "Deine Nachricht wurde erfolgreich gesendet!";
const ERROR_MESSAGE =
  "Beim Senden deiner Nachricht ist ein Fehler aufgetreten. Bitte prüft alle Felder auf Vollständigkeit und versucht es später erneut.";

const MESSAGE_TEMPLATE = (name, phone, email, subject, message) =>
  `${name} has sent a new message.
  PhoneNumber: ${phone}
  Email: ${email}
  Subject of the message: ${subject}
  Following message: ${message}`;

export default {
  components: {
    "vue-recaptcha": RecaptchaComponent,
  },

  data() {
    const { name, phone, email, subject, message } = this;
    return {
      name,
      phone,
      email,
      subject,
      message,
      response: "",
      isAgreed: false,
      submit: false,
      submitSuccess: false,
      notifyMessage: "",
    };
  },

  methods: {
    async handleSubmit() {
      try {
        const { name, email, phone, subject, message } = this;
        const messageText = MESSAGE_TEMPLATE(
          name,
          phone,
          email,
          subject,
          message
        );
        const formData = {
          name,
          email,
          message: messageText,
        };

        await emailjs.send(
          EMAIL_SERVICE_ID,
          EMAIL_TEMPLATE_ID,
          formData,
          EMAIL_USER_ID
        );

        this.submit = true;
        this.submitSuccess = true;
        this.notifyMessage = SUCCESS_MESSAGE;
        this.name = "";
        this.phone = "";
        this.email = "";
        this.subject = "";
        this.message = "";
        this.isAgreed = false;

        setTimeout(() => {
          this.submit = false;
        }, 3000);
      } catch (error) {
        console.log(error);

        this.submit = true;
        this.submitSuccess = false;
        this.notifyMessage = ERROR_MESSAGE;
        setTimeout(() => {
          this.submit = false;
        }, 3000);
      }
    },
    onVerify(response) {
      this.response = response;
      // console.log('reCAPTCHA response:', response);
    },
  },

  computed: {
    isFormValid() {
      return this.isAgreed && !!this.name && !!this.email && !!this.message;
    },
    isRecaptchaValid() {
      return !!this.response;
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}

.view-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 1125px;
  height: calc(100vh - 48px);
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  padding: 32px;
}

.v-application p {
  margin-bottom: 8px;
}

.contact-wrapper,
.map-wrapper {
  display: flex;
  justify-content: center;
  width: 50%;
  background: rgb(55, 55, 55);
}

.contact-wrapper {
  border-radius: 16px 0 0 16px;
  overflow-y: auto;
  overflow-x: hidden;
}

.alert-container {
  display: flex;
  position: absolute;
  max-width: 50%;
  color: #fff;
  background: #4caf50;
  border-radius: 8px;
  top: 0;
  z-index: 1;
}

.alert-container span:first-child {
  background: #fff;
  border-radius: 8px 0 0 8px;
}

.alert-container span:last-child {
  width: 100%;
}

.alert-container span {
  display: flex;
  align-items: center;
  padding: 8px;
}

.alert-container .v-icon {
  color: #4caf50;
}

.map-wrapper {
  border-radius: 0 16px 16px 0;
  position: relative;
  overflow: hidden;
}

.map-wrapper iframe {
  border-radius: 0 16px 16px 0;
}
form {
  padding: 16px 0;
  width: 75%;
}

form .form-title {
  font-size: 2em;
  font-weight: 700;
}

.form-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-message {
  display: flex;
  flex-direction: column;
}

input,
textarea {
  background: rgba(55, 55, 55);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 100%;
  resize: none;
  margin: 4px 0;
  font-size: 0.85em;
  padding: 8px;
  color: #fff;
}

input:focus,
textarea:focus {
  background: #fff;
  color: #333;
}

input {
  height: 36px;
}

textarea {
  height: 275px;
}

.checkbox-wrapper {
  height: 100%;
  display: flex;
  font-size: 0.75em;
  align-items: center;
  gap: 8px;
}

.checkbox-wrapper input[type="checkbox"] {
  width: auto;
  background: transparent;
  box-shadow: none;
}

label.checkbox-wrapper span a {
  text-decoration: none;
  font: inherit;
}

a {
  font-size: 0.65em;
  text-decoration: underline !important;
}

.v-btn {
  margin: 8px 0;
  font-weight: 700;
  justify-content: space-between;
}

@media screen and (max-width: 965px) {
  .view-wrapper {
    flex-direction: column;
    justify-content: center;
  }

  .contact-wrapper,
  .map-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  form {
    width: 50%;
  }

  textarea {
    height: 250px;
  }

  .contact-wrapper {
    border-radius: 16px 16px 0 0;
  }

  .map-wrapper {
    padding-top: 24px;
    border-radius: 0 0 16px 16px;
  }

  .map-wrapper iframe {
    border-radius: 0 0 16px 16px;
  }
}

@media screen and (max-width: 615px) {
  .view-wrapper {
    height: unset;
    justify-content: unset;
  }

  .alert-container[data-v-13b6e01b] {
    margin-left: unset;
    width: 90%;
  }

  form {
    padding-top: 24px;
    width: 90%;
  }

  form .form-title {
    font-size: 1.5em;
  }

  textarea {
    height: 175px;
  }

  .contact-wrapper {
    padding-bottom: 16px;
  }

  .map-wrapper {
    background: transparent;
    padding-top: 0;
    padding-bottom: 16px;
  }
}
</style>
