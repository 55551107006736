<template>
  <div class="view-wrapper">
    <div class="top-section center-content">
      <div class="top-section-left">
        Wir setzen uns dafür ein, unseren Kunden <span>hochwertige</span>
        Logistikdienstleistungen anzubieten.
      </div>
      <span class="d-divider"></span>

      <div class="top-section-right">
        Willst du mehr erfahren? Dann kontaktiere uns jetzt!
        <v-btn rounded color="#E7B202" x-large to="/kontakt"
          >KONTAKT<v-icon>mdi-arrow-right</v-icon></v-btn
        >
      </div>
    </div>

    <div class="bottom-section center-content" alt="PackerCrew GmbH Team">
      <div class="bottom-section-left">
        <div class="card" @click="showModalWithImage('VAS.png')">
          <v-icon class="btn-expand" alt="Value added services">mdi-arrow-expand</v-icon>
          <div class="content-wrapper">
            <p><v-icon class="icon">mdi-package-variant-plus</v-icon></p>
            Value Added Services
          </div>
        </div>
        <div
          class="card"
          @click="showModalWithImage('Online-Shop_Fullfillment.png')"
        >
          <v-icon class="btn-expand">mdi-arrow-expand</v-icon>
          <div class="content-wrapper">
            <p><v-icon class="icon" alt="Online-Shop Fulfillment">mdi-shopping-outline</v-icon></p>
            Online-Shop Fulfillment
          </div>
        </div>
        <MyModal
          v-show="showModal"
          @close="showModal = false"
          :imgSrc="modalImage"
        ></MyModal>
      </div>
    </div>
  </div>
</template>

<script>
import MyModal from "./ModalComponent.vue";

export default {
  components: {
    MyModal,
  },
  data() {
    return {
      showModal: false,
      modalImage: "",
    };
  },
  methods: {
    showModalWithImage(imageName) {
      const imagePath = require(`@/assets/${imageName}`);
      this.modalImage = imagePath;
      this.showModal = true;
    },
  },
};
</script>
<style scoped>

.overlay {
  position: absolute;
  width: 150vw;
  height: 150%;
  top: -15%;
  left: -28%;
  padding: 0;
  margin: 0;
  background: rgb(255 255 255 /10%);
  backdrop-filter: blur(4px);
}
.view-wrapper {
  text-align: center;
}

.top-section {
  display: flex;
  background: #03533a;
}

.top-section-left {
  font-size: 2.1em;
  font-weight: 700;
}

span {
  color: #e7b202;
}

.top-section-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 16px;
}

.top-section-left,
.top-section-right {
  margin: 64px 16px;
}

.v-btn {
  max-width: 300px;
  margin-top: 16px;
  color: #333;
  font-weight: 600;
}

.d-divider {
  display: none;
  }

/* BOTTOM SECTION */
.bottom-section {
  display: flex;
  justify-content: center;
  background: rgb(55, 55, 55);
  background-image: url("../assets/packer-crew-team.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 45%;
  background-position-x:center;
  overflow: hidden;
}

.bottom-section-left {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
  min-height: 420px;
  position: relative;
}

.card {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 16px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: #333;
  cursor: pointer;
  flex-grow: 1;
  max-width: 375px;
  font-size: 1.4em;
}

.btn-expand {
  position: absolute;
  color: #03533a!important;
  top: 8px;
  right: 8px;
  font-size: 0.9em;
}

.bottom-section-left > .card {
  text-wrap: nowrap;
}

.card .content-wrapper p {
  margin: 0;
}

.card .content-wrapper .icon {
  font-size: 1.8em;
  color: #333;
}

.map-card-address {
  height: 150px;
  width: 100%;
  position: absolute;
  background: #e7b202;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 16px;
}

@media screen and (min-width: 1900px) {
    .bottom-section {
      min-height: 600px;
    }
}

@media screen and (min-width: 2180px) {
    .bottom-section {
      min-height: 750px;
    }
}

@media screen and (max-height: 901px) {
  .top-section,
  .bottom-section {
    min-height: calc(35vh - 48px);
  }

  .top-section {
    font-size: 0.8em;
  }

  .bottom-section-left {
    min-height: 330px;
  }


  .top-section-left,
  .top-section-right {
    margin: 16px;
  }

}


  @media screen and (max-width: 965px) {

  .top-section {
    flex-wrap: wrap;
    justify-content: center;
  }

  .top-section-left {
    margin: 0;
  }

  .bottom-section-left {
    margin: 32px;
    flex: 1;
  }

  .top-section-right {
    margin: 0;
    padding: 0;
  }


  .d-divider {
    display: block;
  }

  .card {
    font-size: 1.15rem;
    width: 100%;
    height: 75px;
  }
}

@media screen and (max-width: 615px) {
  .view-wrapper {
    height: unset;
  }

  .top-section {
    flex-wrap: wrap;
  }

  .top-section-left {
    font-size: 1.6rem;
    /* max-width: 90%; */
  }

  .bottom-section {
    flex-direction: column;
    align-items: center;
  }

  .bottom-section-left {
    margin: 0;
    padding: 0 16px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 500px;
    min-height: 315px;
    gap: 0px;
  }

  .card {
    font-size: 1.25rem;
    margin: 8px 0;
    max-width: 350px;
    /* min-height: 75px; */
    height: 75px;
    max-height: 75px;
    width: 270px;
  }
}

@media screen and (max-width: 465px) {
  .bottom-section{
    /*background-size: contain;*/
  }
}

@media screen and (max-width: 376px) {
  .bottom-section-left[data-v-1fc9475a] {
    min-height: 280px;
  }

  div.card {
    margin: 0;
  }
}
</style>
