<template>
    <div class="doc-content view-wrapper">
        <div class="center-content">
            <h2>Einwilligungserklärung zur Kontaktaufnahme</h2>
            <h3>EINWILLIGUNGSERKLÄRUNG ZUR KONTAKTAUFNAHME, GEM. ART 7 EU-DSGVO:</h3>
            <p class="doc-info">Ich willige ein, dass mein Name und meine Kontaktdaten zum Zwecke der künftigen Kontaktaufnahme im Falle durch die PackerCrew GmbH gespeichert und verarbeitet werden.</p>

            <br/>
            <h3>IHRE RECHTE:</h3>
            <p class="doc-info">Ich kann meine Einwilligung jederzeit per E-Mail an info@packercrew.com oder postalisch an PackerCrew GmbH, Lilienthalstraße 10 - 12 | 68642 Bürstadt widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.</p>

            <br/>
            <h3>INFORMATIONEN FÜR BEWERBER</h3>
            <p class="doc-info">Die PackerCrew GmbH verarbeitet die personenbezogenen Daten von Bewerbern zum Zwecke der Abwicklung des Bewerbungsverfahrens. Die Verarbeitung kann auch auf elektronischem Wege erfolgen. Dies ist insbesondere dann der Fall, wenn ein Bewerber entsprechende Bewerbungsunterlagen auf dem elektronischen Wege, beispielsweise per E-Mail oder über ein auf der Internetseite befindliches Webformular, an die PackerCrew GmbH übermittelt. Schließt die PackerCrew GmbH einen Anstellungsvertrag mit einem Bewerber, werden die übermittelten Daten zum Zwecke der Abwicklung des Beschäftigungsverhältnisses unter Beachtung der gesetzlichen Vorschriften gespeichert. Wird von der PackerCrew GmbH kein Anstellungsvertrag mit dem Bewerber geschlossen, so werden die Bewerbungsunterlagen zwei Monate nach Bekanntgabe der Absageentscheidung automatisch gelöscht, sofern einer Löschung keine sonstigen berechtigten Interessen der PackerCrew GmbH entgegenstehen. Sonstige berechtigte Interessen in diesem Sinne sind beispielsweise eine Beweispflicht in einem Verfahren nach dem Allgemeinen Gleichbehandlungsgesetz (AGG).</p>

            


            <br/>
            <h3>VERANTWORTLICHER:</h3>
            <p class="doc-info">PackerCrew GmbH<br/>
            Lilienthalstraße 10 - 12<br/>
            68642 Bürstadt</p>

            <br/>
            <h3>KONTAKT:</h3>
            <p class="doc-info">E-Mail: <a href="mailto:info@packercrew.com" target="_blank">info@packercrew.com</a></p>

            <br/>
            <h3>STAND:</h3>
            <p class="doc-info">Diese Einwilligungserklärung wurde zuletzt am 28. Februar 2024 aktualisiert.</p>
        </div>
    </div>
</template>

<script>
  export default {
    mounted() {
      window.scrollTo(0, 0);
    },
  };
</script>

<style scoped>
.doc-content {
  text-align: left;
}

a {
  text-decoration: none;
  color: #e7b202!important;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>