<template>
  <div class="view-wrapper">
    <div class="main-container center-content">
      <span>Über Uns</span>
      <span class="about-title">Wir streben nach</span>
      <div class="card-list-wrapper">
        <div class="card" v-for="(item, i) in items" :key="i" :class="{ highlight: currentIndex === i }">
          <v-icon>{{ item.icon }}</v-icon>
          <span>{{ item.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        id: 1,
        icon: 'mdi-check-decagram',
        label: 'Qualität'
      },
      {
        id: 2,
        icon: 'mdi-account-heart',
        label: 'Respekt'
      },
      {
        id: 3,
        icon: 'mdi-earth-plus',
        label: 'Nachhaltigkeit'
      },
      {
        id: 4,
        icon: 'mdi-eye-check',
        label: 'Liebe zum Detail'
      },
      {
        id: 5,
        icon: 'mdi-scale-balance',
        label: 'Gerechtigkeit'
      }
    ],
    currentIndex: 0,
  }),
  mounted() {
    setInterval(() => {
      if (this.currentIndex === this.items.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    }, 1000);
  },
}
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  background: rgb(55, 55, 55);
}

.main-container span:first-child {
  color: #E7B202;
}

.main-container .about-title {
  font-size: 2.1rem;
  font-weight: 700;
}

.card-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(116, 116, 116, 0.282);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  position: relative;
  overflow: hidden;
  padding: 32px;
  width: 355px;
  transition: 0.2s ease-in-out;
}

.card:before {
  content: '';
  background: inherit;
  filter: blur(20px);
  /* Change the blur amount as needed */
  position: absolute;
  top: -20px;
  bottom: -20px;
  left: -20px;
  right: -20px;
  z-index: -1;
}

.highlight {
  background-color: #E7B202;
}

.card-list-wrapper .card .v-icon {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
}

.card-list-wrapper .card span {
  display: flex;
  align-items: center;
  color: #fff;
}

.card-list-wrapper .card.highlight .v-icon,
.card-list-wrapper .card.highlight span {
  color: #333;
}

@media screen and (max-width: 965px) {
  .main-container {
    padding: 32px;
  }

  .card-list-wrapper .card span {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 615px) {

  .view-wrapper {
    height: unset;
  }

  .main-container .about-title {
    font-size: 1.5em;
  }

  .card-list-wrapper {
    padding-top: 16px;
  }

  .card {
    height: 115px;
    display: inline-grid;
    padding: 0 8px;
    flex: auto;
    min-width: unset;
    width: auto;
    justify-content: center;
  }
}
</style>
