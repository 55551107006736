import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ContactView from "../views/ContactView.vue";
//-----Docs-----
import DocEinwilligungserklarungView from "../views/DocEinwilligungserklarungView.vue";
import DocDatenschutzerklarungView from "../views/DocDatenschutzerklarungView.vue";
import DocImpressumView from "../views/DocImpressumView.vue";
import DocUrheberrechtView from "../views/DocUrheberrechtView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/kontakt",
    name: "contact",
    component: ContactView,
  },
  //-----Docs-----
  {
    path: "/einwilligungserklarung",
    name: "einwilligungserklarung",
    component: DocEinwilligungserklarungView,
  },
  {
    path: "/datenschutzerklarung",
    name: "datenschutzerklarung",
    component: DocDatenschutzerklarungView,
  },
  {
    path: "/impressum",
    name: "impressum",
    component: DocImpressumView,
  },
  {
    path: "/urheberrecht",
    name: "urheberrecht",
    component: DocUrheberrechtView,
  },
];

const router = new VueRouter({
  routes,
});

router.replace({ path: "/home", redirect: "/" });

export default router;
