<template>
  <v-app>
    <NavigationLayoutVue />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavigationLayoutVue from "./Layouts/NavigationLayout.vue";

export default {
  name: "App",

  components: {
    NavigationLayoutVue,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
html {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #333;
  overflow-y: auto;
}

#app {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  vertical-align: baseline;
  font-size: 1.25em;
  background: #333;
  user-select: none;
}

.v-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 48px;
}

#app.v-application a {
  text-decoration: none !important;
  color: #fff;
}

#app.v-application a.highlight {
  color: #e7b202;
}

.highlight-bg {
  background: #e7b202;
}

.highlight-text {
  color: #e7b202;
}

#app.v-application a.v-btn {
  color: #333;
}

/* Set the scrollbar width and height */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Set the background color of the scrollbar */
::-webkit-scrollbar-track {
  background-color: #ffffff36;
}

/* Set the color of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* border-radius: 4px; */
}

/* Set the color of the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 965px) {
  .center-content {
    padding: 32px 10vw;
  }
}

@media screen and (max-width: 615px) {
  .center-content {
    padding: 32px 10vw;
  }
}
</style>
