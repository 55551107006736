<template>
  <div class="modal-background" @click.self="close">
    <div class="modal-content">
      <img :src="imgSrc" />
      <slot></slot>
      <button class="close-button" @click="close">
        <v-icon>mdi-close</v-icon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.modal-content {
  background-color: white;
  padding: 32px 20px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  max-width: 90vw;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-height: 100%;
}

.modal-content img {
  margin-top: 16px;
  width: 90%;
}

.modal-content i {
  color: #03533a;
  font-size: 1.5em;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
