<template>
  <div class="homepage-wrapper">
    <HomeTopContent ref="home" id="h1-Home" />
    <HomeAboutUsContent ref="uber_uns" id="h2-UberUns" />
    <HomeServicesContent ref="dienstleistungen" id="h4-Services" />
    <HomePartnerServicesContent id="h4-Services" />
    <HomeVideoContent id="VideoContainer" />
    <HomeBottomContent />
  </div>
</template>

<script>
import HomeTopContent from '../components/HomeTopContent'
import HomeAboutUsContent from '../components/HomeAboutUsContent'
import HomeVideoContent from '../components/HomeVideoContent'
import HomeServicesContent from '../components/HomeServicesContent'
import HomePartnerServicesContent from '../components/HomePartnerServicesContent'
import HomeBottomContent from '../components/HomeBottomContent'

export default {
  name: 'HomePage',

  components: {
    HomeTopContent,
    HomeAboutUsContent,
    HomeVideoContent,
    HomeServicesContent,
    HomePartnerServicesContent,
    HomeBottomContent
  },
  mounted() {
    const homeTargetAnchor = window.location.hash

    if (homeTargetAnchor) {
      const homeTarget = this.$refs.home

      window.scrollTo({
        top: homeTarget.offsetTop,
        behavior: 'smooth'
      })
    }

    const uberUnsTargetAnchor = window.location.hash

    if (uberUnsTargetAnchor) {
      const uberUnsTarget = this.$refs.uber_uns

      window.scrollTo({
        top: uberUnsTarget.offsetTop,
        behavior: 'smooth'
      })
    }

    const servicesTargetAnchor = window.location.hash

    if (servicesTargetAnchor) {
      const servicesTarget = this.$refs.dienstleistungen

      window.scrollTo({
        top: servicesTarget.offsetTop,
        behavior: 'smooth'
      })

    }
  }
}
</script>

<style>
.homepage-wrapper {
  height: 100vh;
  display: grid;
  gap: 48px;
}

.view-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100vw;
  color: #fff;
}

.center-content {
  padding: 32px 15vw;
}

.center-content {
  padding: 32px 15vw;
}

.card {
  font-size: 1.75em;
  font-weight: 600;
}

@media screen and (max-width: 615px) {

  .homepage-wrapper {
    height: unset;
  }

  .center-content {
    padding: 32px 5vw;
  }

  /* #VideoContainer {
    display: none;
  } */
}
</style>

